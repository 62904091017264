import logo from "./logo.svg";
import { Box } from "@mui/material";
import "./App.css";
import { Brow } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom/dist";
import ContactCapture from "./screens/ContactCapture";
import ScanText from "./screens/ScanText";
import SearchPage from "./screens/SearchPage";
import ContactDetails from "./screens/ContactDetails";
import CompanyView from "./screens/CompanyView";
import Dashboard from "./screens/Dashboard";
import SendEmail from "./screens/SendEmail";
import MailHistory from "./screens/MailHistory";
import AdminPanel from "./screens/AdminPanel";
import Page404 from "./screens/Page404";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ContactDataGetAction } from "./Redux/Action/ContactDataGetAction";
import { MeetingDataGetAction } from "./Redux/Action/MeetingDataGetAction";
import { EmployeeDetailsGetAction } from "./Redux/Action/EmployeeDetailsGetAction";
import Loader from "./screens/Loader";
import Lottie from "react-lottie-player";
import loadingAnime from "./assets/lottieFiles/loader.json";
import Login from "./screens/Login";
import axios from "./components/Axios/axios";
import { AxiosDefaultPort } from "./constants/Axios";
import ProtectedRoute from "./ProtectedRoute";
import ActivityStatus from "./screens/ActivityStatus";
import EmployeeHierarchy from "./screens/EmployeeHierarchy";
import MainScreen from "./components/Navbar/MainScreen";
import Campaigns from "./screens/BulkEmail/Campaigns";
import ViewCampaigns from "./screens/BulkEmail/ViewCampaigns";
import CampaignDetail from "./screens/BulkEmail/CampaignDetail";
import EmailTemplateEdit from "./screens/BulkEmail/EmailTemplateEdit";
import EmailTemplates from "./screens/BulkEmail/EmailTemplates";
import Testing from "./screens/BulkEmail/Testing";
function App(props) {
  const [empExist, setEmpExist] = useState("NODATA");
  // const [empExist, setEmpExist] = useState(null);
  const [role, setRole] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const SessionToken = window.localStorage.getItem("CMS_STOKEN");
    if (SessionToken) {
      handleSessionVerify(SessionToken);
    }
  }, []);

  const handleSessionVerify = (SessionID) => {
    axios
      .post(AxiosDefaultPort + "apis/Login/sessionTokenVerify", {
        SESSION_TOKEN: SessionID,
      })
      .then((response) => {
        if (response.otpMatch == true) {
          // window.localStorage.setItem(
          //   "CMS_STOKEN",
          //   response.sessionData.SESSION_TOKEN
          // );
          dispatch({
            type: "AUTH_DATA",
            auth_name: response.sessionData.NAME,
            auth_email_id: response.sessionData.EMAIL_ID,
            user_token: response.sessionData.SESSION_TOKEN,
            auth_id: response.sessionData.ID,
          });
        } else {
          window.localStorage.removeItem("CMS_STOKEN");
        }
      });
  };

  useEffect(() => {
    if (props.employee_data.length > 0) {
      if (
        props.employee_data.some((val) => val.email_id == props.auth_email_id)
      ) {
        setEmpExist(true);
        let empRole = props.employee_data.find(
          (val) => val.email_id == props.auth_email_id
        );
        setRole(empRole.role);
        dispatch({
          type: "AUTH_DATA",
          auth_name: empRole.employee_name,
          auth_email_id: empRole.email_id,
          auth_id: empRole._id,
        });
      } else {
        setEmpExist(false);
      }
    }
  }, [props.employee_data]);

  return (
    // <BrowserRouter>
    <>
      {/* <MainScreen /> */}
      <Routes>
        {/* <Route path="/" element={<Dashboard />} /> */}
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/activity-status"
          element={
            <ProtectedRoute>
              <ActivityStatus />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contactCapture"
          element={
            <ProtectedRoute>
              <ContactCapture />
            </ProtectedRoute>
          }
        />
        <Route
          path="/scanText"
          element={
            <ProtectedRoute>
              <ScanText />
            </ProtectedRoute>
          }
        />
        <Route
          path="/searchPage"
          element={
            <ProtectedRoute>
              <SearchPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contactDetails"
          element={
            <ProtectedRoute>
              <ContactDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/companyView"
          element={
            <ProtectedRoute>
              <CompanyView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sendEmail"
          element={
            <ProtectedRoute>
              <SendEmail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employee-hierarchy"
          element={
            <ProtectedRoute>
              <EmployeeHierarchy />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-campaign"
          element={
            <ProtectedRoute>
              <Campaigns />
            </ProtectedRoute>
          }
        />
        <Route
          path="/view-campaign"
          element={
            // <ProtectedRoute>
            <ViewCampaigns />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/campaign-details"
          element={
            <ProtectedRoute>
              <CampaignDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/email-template-edit"
          element={
            <ProtectedRoute>
              <EmailTemplateEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/email-templates"
          element={
            <ProtectedRoute>
              <EmailTemplates />
            </ProtectedRoute>
          }
        />
        <Route
          path="/testing"
          element={
            <ProtectedRoute>
              <Testing />
            </ProtectedRoute>
          }
        />
        <Route path="/mailHistory" element={<MailHistory />} />
        {role == "admin" && (
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminPanel />
              </ProtectedRoute>
            }
          />
        )}
        {role != "admin" && role != "" && (
          <Route path="/admin" element={<Page404 />} />
        )}
   

        {empExist == false && (
          <>
            <Route path="/" element={<Page404 />} />
            <Route path="/contactCapture" element={<Page404 />} />
            <Route path="/scanText" element={<Page404 />} />
            <Route path="/searchPage" element={<Page404 />} />
            <Route path="/contactDetails" element={<Page404 />} />
            <Route path="/companyView" element={<Page404 />} />
            <Route path="/sendEmail" element={<Page404 />} />
            <Route path="/mailHistory" element={<Page404 />} />
            <Route path="/admin" element={<Page404 />} />
          </>
        )}
      </Routes>
      {/* {empExist == "NODATA" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            minHeight:"100vh",
            
          }}
        >
          <Lottie
            // className="loading-lottie"
            style={{
              width: 250,
              height: 250,
            }}
            play
            //   speed={"1"}
            animationData={loadingAnime}
          />
        </Box>
      )} */}
    </>
    // </BrowserRouter>
  );
}

// export default App;
const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  employee_data: state.contactData.employeeList,
});

export default connect(mapStateToProps, {
  ContactDataGetAction,
  MeetingDataGetAction,
  EmployeeDetailsGetAction,
})(App);
