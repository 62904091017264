import React from "react";
import "./styles.css";
function LoginInput({
  Placeholder,
  onChange,
  Value,
  Disabled,
  Type,
  Style,
  error,
  helperText,
  onBlur,
  isRequired,
  id,
  className,
  icon,
  iconOnClick,
  autoComplete,
}) {
  return (
    <div
      style={{
        flexDirection: "column",
        // width: "100%",
      }}
    >
      <div className="group-new">
        <div
          className="text-box-icon-style-new"
          style={{
            backgroundColor: isRequired ? "red" : "",
          }}
        />
        <input
          id={id}
          className={`text-box-style-new ${className} ${
            error ? " text-error" : ""
          }`}
          autoComplete={autoComplete}
          placeholder={Placeholder}
          onChange={onChange}
          value={Value}
          disabled={Disabled}
          type={Type}
          style={{ ...Style, width: "100%" }}
          onBlur={onBlur}
        />
        {icon && (
          <img
            style={{
              marginRight: 5,
              height: 20,
              width: 20,

              cursor: iconOnClick != null && "pointer",
            }}
            src={icon}
            onClick={iconOnClick}
            alt=""
            className="img-responsive"
          />
        )}
      </div>
      <p className="helper-text">{helperText}</p>
    </div>
  );
}

export default LoginInput;
