import React, { useState } from "react";
import {
  Box,
  Toolbar,
  useTheme,
  useMediaQuery,
  CssBaseline,
} from "@mui/material";
// import AppDrawer from "./AppDrawer";
import Navbar from "./Navbar";
import AppDrawer from "./AppDrawer";
export default function MainScreen(props) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <>
      <Box>
        <AppDrawer
          drawerWidth={props.drawerWidth}
          toggleDrawer={setDrawerOpen}
          showDrawer={drawerOpen}
          children={props.children}
          ActiveKey={props.Activekey}
        />
        <Box
          component={"main"}
          sx={{
            width: { lg: `calc(100%)` },
            backgroundColor: "#f0f0f0",
            // minHeight: "88vh",
          }}
        >
          <Navbar
            drawerWidth={props.drawerWidth}
            showDrawer={drawerOpen}
            toggleDrawer={setDrawerOpen}
            ActiveScreen={props.Activekey}
          />
        </Box>
        <Box
          sx={{
            height: 50,
          }}
        />

        <Box>{props.children}</Box>
      </Box>
    </>
  );
}
