import axios from "axios";
import getAccessToken from "../../constants/getAccessToken";
import clientData from "../../constants/clientData";
import { AxiosDefaultPort } from "../../constants/Axios";

export const MeetingDataGetAction = () => (dispatch) => {
  const getSharepointData = (token) => {
    axios
      .post(
        // `https://${clientData.tenant}/sites/${clientData.site}/_api/Web/Lists/getbytitle('meetingDetails')/items`,
        `${AxiosDefaultPort}apis/sharepoint/meetingDataGet`,
        // {token}

        // {
        //   headers: {
        //     Authorization: "Bearer " + token,
        //   },
        // }
      )
      .then((response) => {

        dispatch({
          type: "MEETING_DATA_GET",
          payload: response.data.value,
        });
      });
  };
  getAccessToken(getSharepointData);
};
