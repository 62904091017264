import { useEffect } from "react";
import { connect } from "react-redux";
import { ContactDataGetAction } from "../Redux/Action/ContactDataGetAction";
import { MeetingDataGetAction } from "../Redux/Action/MeetingDataGetAction";
import { MailHistoryGetAction } from "../Redux/Action/MailHistoryGetAction";
import { EmployeeDetailsGetAction } from "../Redux/Action/EmployeeDetailsGetAction";
import { DdOptionsGetAction } from "../Redux/Action/DdOptionsGetAction";
import { AuthAction } from "../Redux/Action/AuthAction";

const RenderDataOnLoad = (props) => {
  useEffect(() => {
    if (props.auth_id) {
      props.ContactDataGetAction(props.auth_id);
      props.MeetingDataGetAction();
      props.MailHistoryGetAction();
      props.EmployeeDetailsGetAction();
      props.DdOptionsGetAction();
      props.AuthAction();
    }
  }, [props.auth_id]);
};

const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  auth_email_id: state.contactData.auth_email_id,
  auth_id: state.contactData.auth_id,
  auth_name: state.contactData.auth_name,
});

// export default RenderDataOnLoad;
export default connect(mapStateToProps, {
  ContactDataGetAction,
  MeetingDataGetAction,
  MailHistoryGetAction,
  EmployeeDetailsGetAction,
  DdOptionsGetAction,
  AuthAction,
})(RenderDataOnLoad);
