import React, { useState, useEffect, useMemo, useRef } from "react";
import "./SendEmail.css";
import {
  Box,
  Card,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  TableBody,
  Checkbox,
  Modal,
  MenuItem,
  Paper,
  Tooltip,
} from "@mui/material";
import CustomInput from "../components/CustomInput/CustomInput";
import Navbar from "../components/Navbar/Navbar";
import { COLORS } from "../constants/theme";
import CustomButton from "../components/CustomButton/CustomButton";
import Icons from "../constants/Icons";
import { AxiosDefaultPort } from "../constants/Axios";
import axios from "axios";
import { IconContext } from "react-icons";
import { RxCross1 } from "react-icons/rx";
import { TiAttachment } from "react-icons/ti";
import { useDropzone } from "react-dropzone";
import { ContactDataGetAction } from "../Redux/Action/ContactDataGetAction";
import { MeetingDataGetAction } from "../Redux/Action/MeetingDataGetAction";
import { connect, useDispatch } from "react-redux";
import JoditEditor from "jodit-react";
import Lottie from "react-lottie-player";
import loadingAnime from "../assets/lottieFiles/loader.json";
import toast, { Toaster } from "react-hot-toast";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import clientData from "../constants/clientData";
import { MailHistoryGetAction } from "../Redux/Action/MailHistoryGetAction";
import ExcelIcon from "../assets/icons/excel-icon.svg";
import ResetIcon from "../assets/icons/reset-icon.svg";
import HistoryIcon from "../assets/icons/history-icon.svg";
import { MultiSelect } from "react-multi-select-component";
import { saveAs } from "file-saver";
// import { app, pages } from "@microsoft/teams-js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import MainScreen from "../components/Navbar/MainScreen";
// Utility function to get unique options for dropdowns
const getUniqueOptions = (data, key) => {
  return [...new Set(data.map((item) => item[key]))].map((item) => ({
    label: item,
    value: item,
  }));
};

// Custom table header with dropdown filters
const TableTh = ({ headText, options, selected, setSelected }) => {
  // Custom renderer to show the count of selected options or nothing
  const valueRenderer = (selected, _options) => {
    if (selected.length === 0) {
      return ""; // Show nothing when no option is selected
    }
    return `${selected.length} selected`; // Show count of selected options
  };

  return (
    <th>
      <Box className="tableth-wrapper">
        <Box></Box>
        {headText}
        <Box sx={{ cursor: "pointer", color: "#000" }}>
          <MultiSelect
            options={options}
            value={selected}
            onChange={setSelected}
            labelledBy="" // Remove header text for selected values
            valueRenderer={valueRenderer} // Custom renderer for selected values
          />
        </Box>
      </Box>
    </th>
  );
};
const copyStringToClipboard = function (str) {
  var el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const facilityMergeFields = [
  "FacilityNumber",
  "FacilityName",
  "Address",
  "MapCategory",
  "Latitude",
  "Longitude",
  "ReceivingPlant",
  "TrunkLine",
  "SiteElevation",
];
const inspectionMergeFields = ["InspectionCompleteDate", "InspectionEventType"];
const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
  let optionGroupElement = document.createElement("optgroup");
  optionGroupElement.setAttribute("label", optionGrouplabel);
  for (let index = 0; index < mergeFields.length; index++) {
    let optionElement = document.createElement("option");
    optionElement.setAttribute("class", "merge-field-select-option");
    optionElement.setAttribute("value", mergeFields[index]);
    optionElement.text = mergeFields[index];
    optionGroupElement.appendChild(optionElement);
  }
  return optionGroupElement;
};
const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "superscript",
  "subscript",
  "|",
  "align",
  "|",
  "ul",
  "ol",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "paragraph",
  "|",
  "image",
  "link",
  "table",
  "|",
  "hr",
  "eraser",
  "copyformat",
  "|",
  "fullsize",
  "selectall",
  "print",
  "|",
  "source",
  "|",
  {
    name: "insertMergeField",
    tooltip: "Insert Merge Field",
    iconURL: "images/merge.png",
    popup: (editor, current, self, close) => {
      function onSelected(e) {
        let mergeField = e.target.value;
        if (mergeField) {
          editor.selection.insertNode(
            editor.create.inside.fromHTML("{{" + mergeField + "}}")
          );
        }
      }
      let divElement = editor.create.div("merge-field-popup");

      let labelElement = document.createElement("label");
      labelElement.setAttribute("class", "merge-field-label");
      labelElement.text = "Merge field: ";
      divElement.appendChild(labelElement);

      let selectElement = document.createElement("select");
      selectElement.setAttribute("class", "merge-field-select");
      selectElement.appendChild(
        createOptionGroupElement(facilityMergeFields, "Facility")
      );
      selectElement.appendChild(
        createOptionGroupElement(inspectionMergeFields, "Inspection")
      );
      selectElement.onchange = onSelected;
      divElement.appendChild(selectElement);

      return divElement;
    },
  },
  {
    name: "copyContent",
    tooltip: "Copy HTML to Clipboard",
    iconURL: "images/copy.png",
    exec: function (editor) {
      let html = editor.value;
      copyStringToClipboard(html);
    },
  },
];
function CustomInputBox({
  Placeholder,
  onChange,
  Value,
  Disabled,
  Type,
  Style,
  error,
  helperText,
  onBlur,
  isRequired,
  icon,
}) {
  return (
    <>
      <div
        className="custom-group"
        // style={{ backgroundColor: "#fdfdfe", width: "100%" }}
      >
        <input
          className={`custom-text-box-style${error ? " text-error" : ""}`}
          placeholder={Placeholder}
          onChange={onChange}
          value={Value}
          disabled={Disabled}
          type={Type}
          style={{ ...Style, width: "100%" }}
          onBlur={onBlur}
        />
      </div>
      <p className="helper-text">{helperText}</p>
    </>
  );
}
const SendEmail = (props) => {
  const editor = useRef(null);
  const [checkedAll, setCheckedAll] = useState(false);
  const [attachFileFlag, setAttachFileFlag] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [editorBody, setEditorBody] = useState("");
  const [viewAccess, setViewAccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [data, setData] = useState([]);
  const [emailModal, setEmailModal] = useState(false);
  const [fileName, setFileName] = useState([]);
  const [emailContent, setEmailContent] = useState({
    to: "",
    cc: "",
    bcc: "",
    subject: "",
    content: "",
  });

  const [htmlContent, setHtmlContent] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    name0: [],
    company: [],
    designation: [],
    // brand: [],
    // mallLocation: [],
    type: [],
    industry: [],
    city: [],
    level: [],
    crManagerName: [],
    department: [],
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editorConfig = useMemo(
    () => ({
      readonly: false,
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      toolbarAdaptive: false,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      processPasteHTML: true,

      //defaultActionOnPaste: "insert_clear_html",
      buttons: buttons,
      uploader: {
        insertImageAsBase64URI: true,
      },
      width: "100%",
      height: 402,
    }),
    []
  );

  useEffect(() => {
    if (props.employee_data) {
      const employee = props.employee_data.find(
        (val) => val.email_id === props.auth_email_id
      );
      if (employee && employee.role === "view") {
        setViewAccess(true);
      }
    }
  }, [props.employee_data, props.auth_email_id]);

  useEffect(() => {
    const tempContact = [...props.contact_data];
    tempContact.map((val) => {
      val = { ...val, checked: false };
    });
    setContactList(tempContact);

    setData(tempContact);
  }, [props.contact_data]);
  useEffect(() => {
    const tempContact = [...props.contact_data];
    tempContact.map((val) => {
      val.checked = false;
    });
    setContactList(tempContact);

    setData(tempContact);
  }, []);

  // const filteredData = useMemo(() => {
  //   if (initialRender) return []; // Return empty array on initial render

  //   return contactList.filter((item) =>
  //     Object.keys(selectedFilters).every(
  //       (key) =>
  //         selectedFilters[key].length === 0 ||
  //         selectedFilters[key].some((filter) => filter.value === item[key])
  //     )
  //   );
  // }, [contactList, selectedFilters, initialRender]);

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = ""; // Re-enable scrolling when not loading
    }

    // Cleanup function to ensure scrolling is re-enabled if the component is unmounted
    return () => {
      document.body.style.overflow = "";
    };
  }, [loading]);

  const [filteredData, setFilteredData] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState({
    name0: [],
    company: [],
    designation: [],
    // brand: [],
    // mallLocation: [],
    type: [],
    industry: [],
    city: [],
    level: [],
    crManagerName: [],
    department: [],
  });
  useEffect(() => {
    if (initialRender) {
      setFilteredData([]);
    } else {
      let temoContact = [...contactList];
      let temp = temoContact.filter((item) =>
        Object.keys(selectedFilters).every(
          (key) =>
            selectedFilters[key].length === 0 ||
            selectedFilters[key].some((filter) => filter.value === item[key])
        )
      );

      setFilteredData(temp);
    }
  }, [contactList, selectedFilters, initialRender]);
  useEffect(() => {
    const filters = {};
    Object.keys(selectedFilters).forEach((key) => {
      const selectedValues = selectedFilters[key].map((f) => f.value);
      filters[key] = getUniqueOptions(
        contactList?.filter((item) => {
          return Object.keys(selectedFilters).every((k) => {
            return (
              selectedFilters[k]?.length === 0 ||
              k === key ||
              selectedFilters[k]?.some((f) => f.value === item[k])
            );
          });
        }),
        key
      );
    });
    setFilteredOptions(filters);
  }, [contactList, selectedFilters]);

  // const filteredOptions = useMemo(() => {
  //   const filters = {};
  //   Object.keys(selectedFilters).forEach((key) => {
  //     const selectedValues = selectedFilters[key].map((f) => f.value);
  //     filters[key] = getUniqueOptions(
  //       contactList.filter((item) => {
  //         return Object.keys(selectedFilters).every((k) => {
  //           return (
  //             selectedFilters[k].length === 0 ||
  //             k === key ||
  //             selectedFilters[k].some((f) => f.value === item[k])
  //           );
  //         });
  //       }),
  //       key
  //     );
  //   });
  //   return filters;
  // }, [contactList, selectedFilters]);

  const sendBulkMail = () => {
    setLoading(true);
    const temp = [];
    const formData = new FormData();
    formData.append("fromMail", props.auth_email_id);
    formData.append("SUBJECT", emailContent.subject);
    formData.append("CONTENT", editorBody);
    formData.append("HTML", htmlContent);

    let bccTemp = emailContent.bcc;
    let finalBcc = [];
    if (bccTemp) {
      finalBcc = bccTemp
        .split(/[\;,]+/)
        .filter((val) => val.includes("@") && val.includes(".com"))
        .map((val) => val.trim());
    }

    let ccTemp = emailContent.cc;
    let finalCc = [];
    if (ccTemp) {
      finalCc = ccTemp
        .split(/[\;,]+/)
        .filter((val) => val.includes("@") && val.includes(".com"))
        .map((val) => val.trim());
    }

    filteredData?.forEach((val) => {
      if (val.checked) {
        temp.push({
          EMAIL_ID: val.email,
          NAME: val.name0,
        });
      }
    });

    formData.append("MAILTO", JSON.stringify(temp));
    formData.append("CC", JSON.stringify(finalCc));
    formData.append("BCC", JSON.stringify(finalBcc));

    fileName.forEach((file) => {
      formData.append(file.name, file);
    });
    for (var pair of formData.entries()) {
    }
    axios
      .post(AxiosDefaultPort + "apis/MailSend/BulkMailSend", formData)
      .then((resMail) => {
        setEmailModal(false);
        setEditorBody("");
        setLoading(false);
        toast.success("Mail sent");
        saveMailHistory(temp, finalCc, finalBcc);
        setEmailContent({
          to: "",
          cc: "",
          bcc: "",
          subject: "",
          content: "",
        });
        setContactList((prev) =>
          prev.map((val) => ({ ...val, checked: false }))
        );
        setData((prev) => prev.map((val) => ({ ...val, checked: false })));
        setFileName([]);
        setEmailModal(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };

  const saveMailHistory = (toMail, cc, bcc) => {
    let authData = new FormData();
    authData.append("grant_type", clientData.grant_type);
    authData.append("client_id", clientData.client_id);
    authData.append("client_secret", clientData.client_secret);
    authData.append("resource", clientData.resource);
    cc.forEach((val) => toMail.push({ EMAIL_ID: val, NAME: val }));
    bcc.forEach((val) => toMail.push({ EMAIL_ID: val, NAME: val }));

    axios
      .get(`${AxiosDefaultPort}apis/sharepoint/getAccessToken`)
      .then((response) => {
        postMailHistory(response.data.access_token, toMail);
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  const postMailHistory = (token, toMail) => {
    let axiosPromises = toMail.map((val) => {
      let mailHistoryPayload = {
        fromMail: props.auth_email_id,
        toMail: val.EMAIL_ID,
        subject: emailContent.subject,
        mailDate: new Date().toDateString(),
      };

      return axios
        .post(`${AxiosDefaultPort}apis/sharepoint/mailHistoryCreate`, {
          mailHistoryPayload,
          token,
        })
        .then(async (response) => {
          mailHistoryPayload.mailDate = new Date().toDateString();
          return mailHistoryPayload;
        })
        .catch((error) => {
          console.error("Error sending mail history:", error);
          return null;
        });
    });

    Promise.all(axiosPromises)
      .then((results) => {
        let validResults = results.filter((result) => result !== null);
        let updatedMailHistory = [...props.mail_history, ...validResults];
        dispatch({
          type: "MAIL_HISTORY_GET",
          payload: updatedMailHistory,
        });
      })
      .catch((error) => {
        console.error("Error in Promise.all():", error);
      });
  };

  const handleUpload = (event) => {
    const file = event.target.files[0];
    setFileName((prev) => [...prev, file]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFileName((prev) => [...prev, ...acceptedFiles]);
    },
    multiple: true,
    maxFiles: 3,
  });
  // --------------pdf download-------------
  const generatePdf = () => {
    const excelData = filteredData.map((val) => ({
      Name: val.name0,
      Designation: val.designation,
      Company: val.company,
      Email: val.email,
      Mobile: val.mobile,
      Landline: val.landline,
      LinkedIn: val.linkedIn,
      Address: val.address,
      Level: val.level,
      // Brand: val.brand,
      City: val.city,
      Type: val.type,
      // Mall_Location: val.mallLocation,
      Industry: val.industry,
      crManagerName: val.crManagerName,
      Department: val.department,
    }));
    const doc = new jsPDF();

    // Define the columns and rows
    const columns = ["Name", "Age", "Email"];
    const rows = data.map((item) => [item.name, item.age, item.email]);

    // Generate the table
    doc.autoTable({
      head: [columns],
      body: rows,
    });
    const csvColumns = [];
    Object.keys(excelData[0]).forEach((key) => csvColumns.push(key));
    // Save the PDF
    doc.save("table.pdf");
  };
  // ------------change csv-------------------
  // const excelDownload = () => {
  //   const excelData = filteredData.map((val) => ({
  //     Name: val.name0,
  //     Designation: val.designation,
  //     Company: val.company,
  //     Email: val.email,
  //     Mobile: val.mobile,
  //     Landline: val.landline,
  //     LinkedIn: val.linkedIn,
  //     Address: val.address,
  //     Level: val.level,
  //     Brand: val.brand,
  //     City: val.city,
  //     Type: val.type,
  //     Mall_Location: val.mallLocation,
  //     Industry: val.industry,
  //     Leasing_Member: val.leasingMember,
  //     Department: val.department,
  //   }));

  //   if (excelData.length > 0) {
  //     // Exclude the "Action" column from csvColumns

  //     const csvColumns = [];
  //     Object.keys(excelData[0]).forEach((key) => csvColumns.push(key));

  //     // const csvColumns = CsvHeader.filter(
  //     //   (column) => column.name !== "Action"
  //     // ).map((column) => column.name);
  //     const csvRows = [];
  //     excelData.map((val) => {
  //       let row = [];
  //       Object.keys(val).forEach((key) => {
  //         row.push(`"${val[key]}"`);
  //       });
  //       csvRows.push(row);
  //     });
  //     const csvContent =
  //       csvColumns.join(",") +
  //       "\n" +
  //       csvRows.map((row) => row.join(",")).join("\n");
  //     const blob = new Blob([csvContent], {
  //       type: "text/csv;charset=utf-8",
  //     });
  //     saveAs(blob, "contact_data.csv"); // Use the saveAs function to download the CSV file
  //   } else {
  //     toast.error("No data to export. Please filter");
  //   }
  // };
  // ------------Test excel-------------------
  // const excelDownload = () => {
  //   fetch("https://example.com/file")
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.style.display = "none";
  //       a.href = url;
  //       a.download = "filename.txt";

  //       document.body.appendChild(a);
  //       a.click();

  //       window.URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => console.error("Download error:", error));
  // };

  // ------------change excel-------------------

  // const excelDownload = () => {
  //   const tempData = filteredData.map((val) => ({
  //     Name: val.name0,
  //     Designation: val.designation,
  //     Company: val.company,
  //     Email: val.email,
  //     Mobile: val.mobile,
  //     Landline: val.landline,
  //     LinkedIn: val.linkedIn,
  //     Address: val.address,
  //     Level: val.level,
  //     Brand: val.brand,
  //     City: val.city,
  //     Type: val.type,
  //     Mall_Location: val.mallLocation,
  //     Industry: val.industry,
  //     Leasing_Member: val.leasingMember,
  //     Department: val.department,
  //   }));

  //   const worksheet = XLSX.utils.json_to_sheet(tempData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  //   // Convert workbook to binary array
  //   const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  //   // Create a Blob from the binary array
  //   const blob = new Blob([wbout], { type: "application/octet-stream" });

  //   if (app.isInitialized()) {
  //     // Open in a new tab to handle download in Teams
  //     const url = URL.createObjectURL(blob);
  //     pages.openWindow(url);
  //   } else {
  //     // Use FileSaver to save the file for browsers
  //     saveAs(blob, "contact_data.xlsx");
  //   }
  // };
  // React.useEffect(() => {
  //   // Initialize the Teams SDK
  //   app.initialize();
  // }, []);
  // ---------------------------
  const excelDownload = () => {
    const tempData = filteredData.map((val) => ({
      Name: val.name0,
      Designation: val.designation,
      Company: val.company,
      Email: val.email,
      Mobile: val.mobile,
      Landline: val.landline,
      LinkedIn: val.linkedIn,
      Address: val.address,
      Level: val.level,
      // Brand: val.brand,
      City: val.city,
      Type: val.type,
      // Mall_Location: val.mallLocation,
      Industry: val.industry,
      crManagerName: val.crManagerName,
      Department: val.department,
    }));
    if (tempData.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(tempData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "Contact_Data.xlsx");
    } else {
      toast.error("No data to export. Please filter");
    }
  };

  const openURLInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <MainScreen>
      <Box
        className="contact-details-container"
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <Toaster
          containerStyle={{
            top: 20,
            left: 20,
            bottom: 20,
            right: 20,
          }}
          gutter={24}
          toastOptions={{
            style: {
              border: "1px solid #grey",
              padding: "16px",
            },
            success: {
              style: {
                background: "#c4fcb1",
                opacity: 0.1,
              },
            },
          }}
        />
        {/* <Navbar headText="CREATE_CAMPAIGN" /> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingX: 1,
            pt: 3,
            pb: 2,
            pl: 2,
          }}
        >
          <Box
            sx={{
              mr: 1,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconContext.Provider value={{ size: 25, color: "#000" }}>
              <FaArrowLeftLong />
            </IconContext.Provider>
          </Box>
          <Typography
            style={{
              fontSize: 22,
              fontWeight: 600,
              color: "#000",
              marginRight: 10,
              textAlign: "center",
            }}
          >
            Quick Mailer
          </Typography>
        </Box>

        <Box className="send-mail-container">
          <Box className="send-mail-inner-container">
            <button
              data-label="Register"
              className="rainbow-hover"
              style={{
                backgroundColor: "#580724",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                setInitialRender(true);
                let temp = [...contactList];
                temp.map((val) => {
                  val.checked = false;
                });
                setContactList(temp);
                setData(temp);
                setSelectedFilters({
                  name0: [],
                  company: [],
                  designation: [],
                  // brand: [],
                  // mallLocation: [],
                  type: [],
                  industry: [],
                  city: [],
                  level: [],
                  crManagerName: [],
                  department: [],
                });
              }}
            >
              <img src={ResetIcon} className="btn-icon" />
              <span className="sp">Reset</span>
            </button>
            {!viewAccess && (
              <button
                data-label="Register"
                className="rainbow-hover"
                onClick={excelDownload}
                style={{
                  backgroundColor: "#23a466",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={ExcelIcon} style={{ width: 25, height: 25 }} />
                <span className="sp">Export</span>
              </button>
            )}
            <button
              data-label="Register"
              className="rainbow-hover"
              onClick={() => {
                navigate("/mailHistory");
              }}
              style={{
                backgroundColor: "#fec32b",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={HistoryIcon} style={{ width: 25, height: 25 }} />
              <span className="sp">History</span>
            </button>
          </Box>

          <Card className="table-container">
            <TableContainer className="email-contacts">
              <Table>
                <TableHead
                  className="scroll-effect"
                  sx={
                    {
                      // border: "1.5px solid #b7b7b7",
                    }
                  }
                >
                  <TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        border: "none",
                        color: "#333",
                        fontWeight: 600,
                        fontSize: 17,
                        backgroundColor: "#fff9b2",
                        textAlign: "center",
                        width: 100,
                        height: 10,
                      },
                    }}
                    component={Paper}
                  >
                    <th className="checkbox-col">
                      <Checkbox
                        size="small"
                        sx={{
                          color: "white", // unchecked color
                          "&.Mui-checked": {
                            color: "white", // checked color
                          },
                        }}
                        checked={checkedAll}
                        onChange={(e) => {
                          setCheckedAll(!checkedAll);

                          let temp = [...contactList];
                          if (e.target.checked) {
                            temp.map((val) => {
                              if (
                                filteredData.some((fil) => fil.ID == val.ID)
                              ) {
                                val.checked = true;
                              }
                            });
                          } else {
                            temp.map((val) => {
                              val.checked = false;
                            });
                          }
                          setContactList(temp);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </th>
                    {[
                      { headText: "Contact Name", param: "name0" },
                      { headText: "Company Name", param: "company" },
                      { headText: "Designation", param: "designation" },
                      // { headText: "Brand", param: "brand" },
                      // { headText: "Mall Location", param: "mallLocation" },
                      { headText: "Type", param: "type" },
                      { headText: "Industry", param: "industry" },
                      { headText: "City", param: "city" },
                      { headText: "Level", param: "level" },
                      { headText: "CR Manager", param: "crManagerName" },
                      { headText: "Department", param: "department" },
                    ].map(({ headText, param }) => (
                      <TableTh
                        key={param}
                        headText={headText}
                        options={filteredOptions[param]}
                        selected={selectedFilters[param]}
                        setSelected={(selected) => {
                          // -----------
                          let tempContact = [...contactList];
                          tempContact.map((val) => {
                            if (!filteredData.some((fil) => fil.ID == val.ID)) {
                              val.checked = false;
                            }
                          });
                          setContactList(tempContact);
                          // -----------
                          function findValue(obj) {
                            for (const value of Object.values(obj)) {
                              if (Array.isArray(value) && value.length > 0) {
                                return true;
                              }
                            }
                            return false;
                          }
                          let temp = { ...selectedFilters };
                          temp[param] = selected;
                          if (findValue(temp) > 0) {
                            setInitialRender(false);
                          } else {
                            setInitialRender(true);
                          }
                          setSelectedFilters((prev) => ({
                            ...prev,
                            [param]: selected,
                          }));
                        }}
                      />
                    ))}
                    <th>Email Id</th>
                    <th>Mobile Number</th>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredData.map((val, index) => (
                    <TableRow
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setData((prev) =>
                          prev.map((item, idx) =>
                            idx === index
                              ? { ...item, checked: !item.checked }
                              : item
                          )
                        );
                      }}
                    >
                      <td style={{ textAlign: "center", fontSize: 10 }}>
                        <Checkbox
                          size="small"
                          checked={val.checked}
                          // onClick={(e) => {
                          //   let temp = [...data];
                          //   temp[index].checked = !temp[index].checked;
                          // }}
                          // value={kpiChecked}
                          onChange={(e) => {
                            let temp = [...contactList];
                            const index = temp.findIndex(
                              (obj) =>
                                JSON.stringify(obj) === JSON.stringify(val)
                            );
                            temp[index].checked = !temp[index].checked;
                            setContactList(temp);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </td>
                      <td style={{ textAlign: "center", fontSize: 16 }}>
                        {val.name0}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 16 }}>
                        {val.company}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 16 }}>
                        {val.designation}
                      </td>
                      {/* <td style={{ textAlign: "center", fontSize: 16 }}>
                      {val.brand}
                    </td> */}
                      {/* <td style={{ textAlign: "center", fontSize: 16 }}>
                      {val.mallLocation}
                    </td> */}
                      <td style={{ textAlign: "center", fontSize: 16 }}>
                        {val.type}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 16 }}>
                        {val.industry}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 16 }}>
                        {val.city}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 16 }}>
                        {val.level}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 16 }}>
                        {val.crManagerName}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 16 }}>
                        {val.department}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 16 }}>
                        <Box className="tdb-box">{val.email}</Box>
                      </td>
                      <td style={{ textAlign: "center", fontSize: 16 }}>
                        {val.mobile}
                      </td>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>

          <Box sx={{ mt: 3, display: "flex", justifyContent: "end" }}>
            <CustomButton
              Label="Send Email"
              buttonColor="#1770E0"
              style={{
                // cursor: "pointer",
                width: 100,
                height: 30,
                borderRadius: 5,
                marginRight: 15,
              }}
              labelStyle={{
                color: COLORS.white,
                fontSize: 15,
                fontWeight: "bold",
              }}
              onClick={() => {
                if (filteredData.some((val) => val.checked == true)) {
                  setEmailModal(true);

                  let temp = [...filteredData];
                  let toString = "";
                  temp.map((val, index) => {
                    if (val.checked == true) {
                      toString += "; " + val.name0;
                    }
                  });
                  toString = toString.substring(1);
                  let tempEmailData = { ...emailContent };
                  tempEmailData.to = toString;
                  setEmailContent(tempEmailData);
                } else {
                  toast.error("Please select contact");
                }
              }}
            />
          </Box>
        </Box>
        <Modal
          disableEnforceFocus
          open={emailModal}
          onClose={() => {
            setEmailModal(false);
            setAttachFileFlag(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // style={{ overflowY: "scroll", top: "5%", paddingTop: 15 }}
        >
          <Box className="email-modal-box">
            <Box className="emodal-box">
              <IconContext.Provider value={{ size: 15, marginTop: 5 }}>
                <RxCross1
                  onClick={() => {
                    setEmailModal(false);
                    setAttachFileFlag(false);
                  }}
                />
              </IconContext.Provider>
            </Box>
            <Box className="flex-row-email">
              <Typography
                sx={{
                  fontSize: 17,
                  ml: 2,
                  color: COLORS.gray70,
                  fontWeight: "bold",
                }}
              >
                Send Email
              </Typography>
              <input
                id="attach"
                type="file"
                onChange={(e) => handleUpload(e, fileName)}
                className="hidden"
              />
              <label for="attach">
                <Tooltip title="Attach file">
                  <Box
                    for="attach"
                    className="attach-box"
                    // onClick={() => setAttachFileFlag(!attachFileFlag)}
                  >
                    <IconContext.Provider
                      value={{ size: 20, color: COLORS.gray40 }}
                    >
                      <TiAttachment />
                    </IconContext.Provider>
                  </Box>
                </Tooltip>
              </label>
            </Box>
            {/* ---------email file select------------ */}
            <Box className="handle-scroll">
              {attachFileFlag && (
                // <SupportingFileImport />
                <>
                  <section style={{ marginRight: 30, marginLeft: 10 }}>
                    <div></div>
                  </section>
                </>
              )}
              {fileName.length > 0 && (
                <Box className="file-list">
                  {fileName.map((val, index) => {
                    return (
                      <div className="file-container">
                        <text
                          style={{
                            color: "#000",
                            fontSize: 14,
                            marginLeft: 10,
                            // fontWeight: 700,
                          }}
                          onClick={() => {
                            openURLInNewTab(val.path);
                          }}
                        >
                          {val?.name}
                        </text>
                        <IconContext.Provider
                          value={{
                            size: 15,
                            style: {
                              marginRight: 10,
                              // width: 30,
                              // height: 30,
                              color: "red",
                            },
                          }}
                        >
                          <RxCross1
                            onClick={() => {
                              let tempFile = [...fileName];
                              tempFile.splice(index, 1);
                              setFileName(tempFile);
                            }}
                          />
                        </IconContext.Provider>
                      </div>
                    );
                  })}
                </Box>
              )}
              {/* ----------email file select end--------- */}
              <Box>
                <Box className="to-container">
                  <Box className="to-box">
                    <Box className="to-textbox">
                      <Typography
                        style={{
                          fontSize: 15,
                          color: COLORS.gray50,
                          // marginRight: 10,
                        }}
                      >
                        To
                      </Typography>
                    </Box>
                    <Box className="to-input-container">
                      <CustomInputBox Disabled Value={emailContent.to} />
                    </Box>
                  </Box>
                  <Box className="to-box">
                    <Box className="to-textbox">
                      <Typography
                        style={{ fontSize: 15, color: COLORS.gray50 }}
                      >
                        Cc
                      </Typography>
                    </Box>

                    <Box className="to-input-container">
                      <CustomInputBox
                        // error={val.emptyFlag}
                        // Placeholder="Subject"
                        Value={emailContent.cc}
                        onChange={(e) => {
                          let temp = { ...emailContent };
                          temp.cc = e.target.value;
                          setEmailContent(temp);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className="to-box">
                    <Box className="to-textbox">
                      <Typography
                        style={{ fontSize: 15, color: COLORS.gray50 }}
                      >
                        Bcc
                      </Typography>
                    </Box>
                    <Box className="to-input-container">
                      <CustomInputBox
                        // error={val.emptyFlag}
                        // Placeholder="Subject"
                        Value={emailContent.bcc}
                        onChange={(e) => {
                          let temp = { ...emailContent };
                          temp.bcc = e.target.value;
                          setEmailContent(temp);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className="to-box">
                    <Box className="to-textbox">
                      <Typography
                        style={{ fontSize: 15, color: COLORS.gray50 }}
                      >
                        Subject
                      </Typography>
                    </Box>

                    <Box className="to-input-container">
                      <CustomInputBox
                        // error={val.emptyFlag}
                        // Placeholder="Subject"
                        Value={emailContent.subject}
                        onChange={(e) => {
                          let temp = { ...emailContent };
                          temp.subject = e.target.value;
                          setEmailContent(temp);
                        }}
                      />
                    </Box>
                  </Box>
                  {/* <Typography style={{ fontSize: 15, color: COLORS.gray50 }}>
                Text
              </Typography>
              <InputBox
                Value={emailContent.content}
                onChange={(e) => {
                  let temp = { ...emailContent };
                  temp.content = e.target.value;
                  setEmailContent(temp);
                }}
              /> */}
                  {/* <Typography style={{ fontSize: 15, color: COLORS.gray50 }}>
                HTML
              </Typography>
              <InputBox
                Value={htmlContent}
                onChange={(e) => {
                  setHtmlContent(e.target.value);
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                mr: 4,
                mb: 2,
              }}
            > */}
                  <Box className="editor-container">
                    {/* <Editor
                  onEditorChange={onEditorChange}
                  //initialValue={content}
                  //outputFormat="text"
                  value={emailContent.content}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  // initialValue="<p>This is the initial content of the editor.</p>"
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      "mentions advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | emoticons| help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    emoticons_append: {
                      custom_mind_explode: {
                        keywords: ["brain", "mind", "explode", "blown"],
                        char: "🤯",
                      },
                    },
                  }}
                /> */}
                    <JoditEditor
                      ref={editor}
                      // value={editorBody}
                      config={editorConfig}
                      onChange={(content) => setEditorBody(content)}
                    />
                  </Box>

                  <CustomButton
                    Label="Send"
                    buttonColor="#1770E0"
                    style={{
                      width: 80,
                      height: 25,
                      borderRadius: 5,
                      marginTop: 25,
                      marginBottom: 5,
                    }}
                    labelStyle={{
                      color: "#fff",
                      fontSize: 15,
                    }}
                    onClick={sendBulkMail}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>

        {loading && (
          <Box className="loading-animation">
            <Lottie
              loop
              animationData={loadingAnime}
              play
              style={{ width: 300, height: 300 }}
            />
          </Box>
        )}
      </Box>
    </MainScreen>
  );
};

const mapStateToProps = (state) => {
  return {
    contact_data: state.contactData.contactDataList,
    meeting_data: state.contactData.meetingDataList,
    mail_history: state.contactData.mailHistoryList,
    auth_email_id: state.contactData.auth_email_id,
    auth_name: state.contactData.auth_name,
  };
};

export default connect(mapStateToProps, {
  ContactDataGetAction,
  MeetingDataGetAction,
  MailHistoryGetAction,
})(SendEmail);
