import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

function Testing() {
  let MasterCardData = [
    {
      TITLE: "Img 1",
      KEY: "IMG_1",
      IMAGE_FILE: null,
      IMAGE_URL: null,
      SELECTED: false,
    },
    {
      TITLE: "Img 2",
      KEY: "IMG_2",
      IMAGE_FILE: null,
      IMAGE_URL: null,
      SELECTED: false,
    },
    {
      TITLE: "Img 3",
      KEY: "IMG_3",
      IMAGE_FILE: null,
      IMAGE_URL: null,
      SELECTED: false,
    },
  ];
  const [CardData, setCardData] = useState(MasterCardData);

  const handleCardSelect = (UniqueKey) => {
    const index = CardData.findIndex((item) => item.KEY == UniqueKey);

    let tempCardData = [...CardData];

    if (index != -1) {
      tempCardData.map((val) => {
        val.SELECTED = false;
      });
      tempCardData[index].SELECTED = !tempCardData[index].SELECTED;
    }

    setCardData(tempCardData);
  };

  const handleFileUpload = (e) => {
    console.log("sadnasjhdasdsa", e.target.files[0]);

    let uploadedFile = e.target.files[0];

    let tempCardData = [...CardData];
    const checkSelectedCard = CardData.findIndex((val) => val.SELECTED == true);
    if (checkSelectedCard != -1) {
      tempCardData[checkSelectedCard].IMAGE_FILE = e.target.files[0];

      const reader = new FileReader();
      let fileUrl;
      const FilePath = new Promise((resolve, reject) => {
        reader.onload = (e) => {
          fileUrl = e.target.result;
          resolve(fileUrl);
        };
      });
      reader.readAsDataURL(uploadedFile);
      FilePath.then((response) => {
        tempCardData[checkSelectedCard].IMAGE_URL = response;
        setCardData(tempCardData);
      });

      toast.success("Image uploaded in card " + checkSelectedCard + 1);
    } else {
      toast.error("Please select a card to upload the image");
    }
  };

  const handleFileSubmit = async () => {
    const filesBase64 = CardData.map((val) => val.IMAGE_URL);
    console.log("asdjasjkdasd", filesBase64);

    await fetch("http://requestbin.cn:80/18n02at1", {
      method: "POST",
      body: JSON.stringify({
        IMAGE: filesBase64,
      }),
    })
      .then((response) => {
        toast.success("Images Upload successfully");
        setCardData(MasterCardData);
      })
      .catch(() => {
        toast.success("Something went wrong");
      })
      .finally(() => {
        console.log("finished");
      });
  };
  return (
    <div>
      <Toaster />
      <div
        style={{
          height: 60,
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={
            "https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/IDEMIA_logo.svg/1600px-IDEMIA_logo.svg.png?20210901033752"
          }
          style={{
            width: 200,
            height: 50,
          }}
        />
      </div>
      <div
        style={{
          height: 3,
          backgroundColor: "blue",
        }}
      />
      <div
        style={{
          padding: 10,
        }}
      >
        <input
          type="file"
          style={{
            display: "none",
          }}
          id="imgInput"
          onChange={(e) => {
            handleFileUpload(e);
          }}
        />
        <label
          htmlFor="imgInput"
          className="signup-button"
          style={{
            minWidth: 200,
            padding: 10,
          }}
        >
          Start Capture
        </label>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        {CardData.map((val) => {
          console.log("kjnjknjkasdasdsa", val.IMAGE_URL);

          return (
            <Card
              onClick={() => {
                handleCardSelect(val.KEY);
              }}
              sx={{
                backgroundColor: val.SELECTED ? "#dedede" : null,
                p: 5,
              }}
            >
              <p
                style={{
                  fontSize: 20,
                  fontFamily: "poppins",
                }}
              >
                {" "}
                {val.TITLE}
              </p>
              <img
                src={
                  val.IMAGE_URL ||
                  "https://skala.or.id/wp-content/uploads/2024/01/dummy-post-square-1-1.jpg"
                }
                style={{
                  width: "100%",
                  height: 150,
                  objectFit: "cover",
                }}
              />
            </Card>
          );
        })}
      </div>

      <div
        style={{
          padding: 10,
        }}
      >
        <button
          className="signup-button"
          style={{
            width: 200,
            padding: 10,
          }}
          onClick={() => {
            handleFileSubmit();
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default Testing;
