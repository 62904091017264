// https://inorbitcontactmanagement.kraheja.com
import React, { useState, useEffect } from "react";
// import * as microsoftTeams from "@microsoft/teams-js";

export const AuthAction = () => (dispatch) => {
  // ----------inside teams-----------
  // var family_name;
  // var email;
  // var given_name;
  // var user_token;
  // const getUserDetails = async (token) => {
  //   // Decode JWT to extract the payload

  //   const base64Url = token.split(".")[1]; // Get the payload part of the token
  //   const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Normalize base64 string
  //   const payload = decodeURIComponent(
  //     window
  //       .atob(base64)
  //       .split("")
  //       .map(function (c) {
  //         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  //       })
  //       .join("")
  //   );

  //   const decodedToken = JSON.parse(payload);

  //   // Extract 'oid' from the decoded token
  //   if (decodedToken != null) {
  //     // family_name = decodedToken?.family_name;
  //     given_name = decodedToken?.name;
  //     email =
  //       decodedToken?.upn == undefined || decodedToken?.upn == null
  //         ? decodedToken?.preferred_username
  //         : decodedToken?.upn;
  //     dispatch({
  //       type: "AUTH_DATA",
  //       auth_email_id: email,
  //       auth_name: given_name,
  //       email,
  //     });
  //   }
  //   // Instead of fetching user details, directly set OID and token in state
  // };
  // microsoftTeams.app.initialize().then(() => {
  //   microsoftTeams.authentication.getAuthToken({
  //     successCallback: (token) => {
  //       user_token = token;
  //       getUserDetails(token);
  //     },
  //     failureCallback: (error) => {
  //       console.error("Error getting token:", error);
  //     },
  //   });
  // });
  //-----------outside teams------------
  // dispatch({
  //   type: "AUTH_DATA",
  //   user_token: "",
  // });
  // -------------------
};
