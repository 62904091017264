import React, { useEffect, useState } from "react";
import MainScreen from "../../components/Navbar/MainScreen";
import axios from "axios";
import AXIOS from "../../utils/AXIOS";
import { connect } from "react-redux";

import { Box, Card, Typography } from "@mui/material";
import "./ViewCampaign.css";
import { useNavigate } from "react-router-dom";
import { campaignStatus, formatDateToDDMMYYYY } from "../../utils/Constants";
import Lottie from "react-lottie-player";
import loadingAnime from "../../assets/lottieFiles/loader.json";
import NoDataFoundAnime from "../../assets/lottieFiles/noData.json";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IconContext } from "react-icons";
import toast from "react-hot-toast";

function ViewCampaigns(props) {
  const navigate = useNavigate();
  const [transactionFlags, setTransactionFlags] = useState({
    TBODY_LOADING: true,
    TBODY_EMPTY: false,
  });
  useEffect(() => {
    if (props.auth_id) {
      getCampaigns();
    }
  }, [props.auth_id]);

  const [Tbody, setTbody] = useState([]);
  const getCampaigns = () => {
    axios
      .post(AXIOS.defaultPort + AXIOS.getCampaign, {
        auth_id: props.auth_id,
      })
      .then((response) => {
        let tempTransactionFlags = { ...transactionFlags };
        tempTransactionFlags.TBODY_LOADING = false;
        if (response.data.length > 0) {
          console.log("Asdjnasnjkdasd", response.data);

          setTbody(response.data);
        } else {
          tempTransactionFlags.TBODY_EMPTY = true;
        }
        setTransactionFlags(tempTransactionFlags);
      })
      .catch((err) => {
        setTransactionFlags((prev) => ({
          ...prev,
          TBODY_DATA_FOUND: false,
        }));
        toast.error("Something went wrong");
      });
  };

  return (
    <MainScreen>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 3,
          paddingBottom: 1,
          paddingLeft: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              mr: 1,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconContext.Provider value={{ size: 25, color: "#000" }}>
              <FaArrowLeftLong />
            </IconContext.Provider>
          </Box>
          {/* <h9 className="header-text">Contact Capture</h9> */}
          <Typography
            style={{
              fontSize: 22,
              fontWeight: 600,
              color: "#000",
              marginRight: 10,
              textAlign: "start",
            }}
          >
            View Campaigns
          </Typography>
        </Box>
      </Box>
      {transactionFlags.TBODY_LOADING == true && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <Lottie
            style={{
              width: 250,
              height: 250,
            }}
            play
            //   speed={"1"}
            animationData={loadingAnime}
          />
        </Box>
      )}
      {transactionFlags.TBODY_EMPTY == true && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            minHeight: "70vh",
            alignItems: "center",
          }}
        >
          <div>
            <Lottie
              style={{
                width: 350,
                height: 350,
              }}
              play
              animationData={NoDataFoundAnime}
            />
            <p
              style={{
                textAlign: "center",
              }}
              className="recipient-description"
            >
              No Data Found
            </p>
          </div>
        </Box>
      )}
      <Box className="campaign-card-grid-container">
        {Tbody.map((val) => {
          return (
            <div
              className="campaign-card"
              onClick={() => {
                navigate("/campaign-details", {
                  state: {
                    campaign_id: val._id,
                  },
                });
              }}
            >
              <div className="header">
                <div className="left-section">
                  <div className="left-section-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#FFFFFF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                  </div>
                  <div className="details">
                    <h3>{val.CAMPAIGN_NAME}</h3>
                    <p>{val.CAMPAIGN_DESCRIPTION}</p>
                  </div>
                </div>
                <div className="right-section">
                  {/* <span className="messages">{val.CREATED_TIME}</span> */}
                  {/* <span className="segments">04</span> */}
                  <span className="status running">
                    <div className="dot" />{" "}
                    {campaignStatus.find(
                      (item) => item.value == val.CAMPAIGN_STATUS
                    )?.label || ""}
                  </span>
                  {/* <button className="menu-button">...</button> */}
                </div>
              </div>

              {/* Tags */}
              <div className="tags">
                <span className="tag">
                  {/* {val.CREATED_TIME} */} Created On :{" "}
                  <span>
                    {val.CREATED_TIME
                      ? formatDateToDDMMYYYY(val.CREATED_TIME)
                      : ""}{" "}
                  </span>
                  <span
                    style={{
                      marginLeft: 5,
                    }}
                  >
                    {val.CREATED_TIME
                      ? new Date(val.CREATED_TIME).toLocaleString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: true,
                        })
                      : "--"}
                  </span>
                </span>
                <span className="tag">Created By: {val.CREATED_BY_NAME}</span>
              </div>

              {/* Stats */}
              <div className="bottom-stats">
                <div className="stats">
                  <div className="stat">
                    <h4>
                      {val.DELIVERED_COUNT != null ? val.DELIVERED_COUNT : "--"}
                    </h4>
                    <p>
                      Total Delivered <br />
                    </p>
                  </div>
                  <div className="stat">
                    <h4>
                      {val.OPENED_COUNT != null ? val.OPENED_COUNT : "--"}
                    </h4>
                    <p>
                      Total Opened <br />
                    </p>
                  </div>
                  {/* <div className="stat">
                    <h4>{}</h4>
                    <p>
                      Clicked <br /> Last 30 Days
                    </p>
                  </div> */}
                  <div className="stat">
                    <h4>
                      {val.BOUNCED_COUNT != null ? val.BOUNCED_COUNT : "--"}
                    </h4>
                    <p>
                      Total Bounced <br /> 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Box>
    </MainScreen>
  );
}

const mapStateToProps = (state) => ({
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  auth_id: state.contactData.auth_id,
});

export default connect(mapStateToProps, {})(ViewCampaigns);
